import React from "react"
//import { makeStyles } from "@material-ui/core"

/* const useStyles = makeStyles(theme => ({
  iframevideo: {
    position: "absolute",
    top: "0%",
    left: "0%",
    width: "100%",
    minHeight: "80%",
    border: 0,
    borderRadius: 1,
  },

})) */

const Videos = ({ video, ...props }) => {
//  const classes = useStyles()
  if (video.mostrar) {
    return (
      <iframe
       // className={classes.iframevideo}
        style={{background: `${video.color_fondo_marco}`, padding: 15, height: "100%", width: "100%"}}
        src={video.link}
        title={video.nombre}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    )
  } else {
    return ""
  }
}

export default Videos
