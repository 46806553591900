import React from "react"
import { Tooltip, Button } from "@material-ui/core"
import "../css/background-image.css"
import {
  injectIntl,
  Link,
  navigate
} from "gatsby-plugin-intl"

const Botones = ({ intl, boton }) => {
  const {
    link,
    mostrar,
    size,
    texto,
    tipo_link,
    variante,
    title,
    color_fondo,
    color_letra,
  } = boton

  const currentLocale = intl.locale

  if (mostrar) {
    if (tipo_link === "interno") {
      return (
        <div className="fondoBoton" >
          {/* <Link to={link} style={{ textDecoration: "none" }}> */}
            <Tooltip title={currentLocale === "en"
                ? title.en
                : currentLocale === "pt"
                ? title.pt
                : title.es} arrow placement="top">
              <Button
                fullWidth
                variant={variante}
                style={{
                  background: `${color_fondo}`,
                  color: `${color_letra}`,
                }}
                onClick={() => navigate(link)}
                size={size}
              >
                {currentLocale === "en"
                ? texto.en
                : currentLocale === "pt"
                ? texto.pt
                : texto.es}
              </Button>
            </Tooltip>
       {/*    </Link> */}
        </div>
      )
    } else {
      return (
        <a
          href={link}
          style={{ textDecoration: "none" }}
          rel="noreferrer"
          target="_blank"
        >
          <Tooltip title={currentLocale === "en"
                ? title.en
                : currentLocale === "pt"
                ? title.pt
                : title.es} arrow placement="top">
            <Button
              fullWidth
              variant={variante}
              style={{ background: `${color_fondo}`, color: `${color_letra}` }}
              size={size}
            >
              {currentLocale === "en"
                ? texto.en
                : currentLocale === "pt"
                ? texto.pt
                : texto.es}
            </Button>
          </Tooltip>
        </a>
      )
    }
  } else {
    return ""
  }
}

export default injectIntl(Botones)
