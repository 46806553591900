import React, { useEffect, useState } from "react"
import { Button } from "@material-ui/core"
import { GatsbyImage } from "gatsby-plugin-image"
import "../css/background-image.css"
import { navigate } from "gatsby-plugin-intl"

const CartelesMovil = props => {
  const {
 //   proporcion,
    tiempo_slideshow,
    // width,
    // mostrar,
    mostrar_celular,
    color_fondo_marco,
    slideshow_imagenes,
  } = props.cartel

  let i
  const [ind, setInd] = useState(0)
  const cambiarImagen = () => {
    if (i < slideshow_imagenes.length - 1) {
      i = i + 1
      setInd(ind => ind + 1)
    } else {
      setInd(0)
      i = 0
    }
  }

  const irA = () => {
    if (slideshow_imagenes[ind].imagen_link === " ") {
    } else {
      if (slideshow_imagenes[ind].imagen_link_tipo === "interno") {
        navigate(slideshow_imagenes[ind].imagen_link)
      } else {
        window.open(slideshow_imagenes[ind].imagen_link, "_blank")
      }
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      cambiarImagen()
    }, tiempo_slideshow)
    return () => clearInterval(interval)
  }, [])

  return (
    <>
      {mostrar_celular ? (
        <Button
          style={{ background: `${color_fondo_marco}`, padding: 15 }}
          type="button"
          onClick={irA}
        >
          {slideshow_imagenes[ind].imagen !== null &&
          slideshow_imagenes[ind].imagen.localFile !== null ? (
            <GatsbyImage
              image={
                slideshow_imagenes[ind].imagen.localFile.childImageSharp
                  .gatsbyImageData
              }
              alt="imagen"
            />
          ) : (
            ""
          )}
        </Button>
      ) : (
        ""
      )}
    </>
  )
}

export default CartelesMovil
