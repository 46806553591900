import { graphql, useStaticQuery } from "gatsby"

const usePabellones = () => {
  const resultado = useStaticQuery(
    graphql`
      query {
        allStrapiPabellones {
          nodes {
            nombre
            pabellon_oculto
            id
            title
            nombre_en
            nombre_pt
            title_pt
            title_en
            width
            top
            left
            boton_size
            boton_color_fondo
            boton_color_letra
            boton_variante
            color_fondo_marco
            imagen_cartelera_mostrar
            imagen_cartelera {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 600
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    `
  )
  return resultado.allStrapiPabellones.nodes.map(pabellon => ({
    nombre: pabellon.nombre,
    imagen_cartelera: pabellon.imagen_cartelera,
    id: pabellon.id,
    width: pabellon.width,
    top: pabellon.top,
    boton_color_letra: pabellon.boton_color_letra,
    boton_color_fondo: pabellon.boton_color_fondo,
    color_fondo_marco: pabellon.color_fondo_marco,
    boton_size: pabellon.boton_size,
    boton_variante: pabellon.boton_variante,
    left: pabellon.left,
    title: pabellon.title,
    nombre_pt: pabellon.nombre_pt,
    title_pt: pabellon.title_pt,
    nombre_en: pabellon.nombre_en,
    title_en: pabellon.title_en,
    pabellon_oculto: pabellon.pabellon_oculto,
    imagen_cartelera_mostrar: pabellon.imagen_cartelera_mostrar,
  }))
}

export default usePabellones
