import React from "react"
import { styled, Box, Divider } from "@material-ui/core"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
// import { link, titulo } from "../css/listadoStands.module.css"
import urlSlug from "url-slug"

const MyBox = styled(Box)({
  background: "background.paper",
  height: "100%",
  width: "100%",
  direction: 'row'
})

const StandPreviewLista = ({ stand }) => {
  const { nombre, imagen_pabellon, title, nombre_pabellon } = stand
  return (
    <div title={title}>
      {/*   <Link to={urlSlug(nombre)}> */}
      <Link
        to={`/${urlSlug(nombre)}`}
        style={{ textDecoration: "none", color: "black", whiteSpace: "nowrap" }}
      >
        <MyBox>
        <div style={{ width: "6%", margin: 20 }}>
          {imagen_pabellon.localFile !== null ? (
            <GatsbyImage
              image={imagen_pabellon.localFile.childImageSharp.gatsbyImageData}
              alt="imagen"
              //    style={{height: altura}}
            />
          ) : (
            ""
          )}
        </div>
        <div style={{ textAlign: "center", margin: `5px auto`, fontSize: 12 }}>
          {nombre} - {nombre_pabellon}
        </div>
        </MyBox>
      </Link>
      <Divider />
    </div>
  )
}

export default StandPreviewLista
