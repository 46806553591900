import React, { useState, useEffect } from "react"
import { injectIntl } from "gatsby-plugin-intl"
import {
  Modal,
  Button,
  CssBaseline,
  TextField,
  makeStyles,
  InputAdornment,
  Divider,
  Tooltip
} from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import useStandsTodos from "../hooks/useStandsTodos"
import StandPreviewLista from "./StandPreviewLista"

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "80%",
    height: "90%",
    left: "10%",
    top: "5%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

const ModalBuscadorStands = ({ intl, modal_buscador_stands }) => {
  const classes = useStyles()
  const resultado = useStandsTodos()
  const [stands, setStands] = useState([])
  const [search, setSearch] = useState("")

  useEffect(() => {
    filtrado()
  }, [search])

  const filtrado = () => {
    setStands(
      resultado.filter(node =>
        node.palabras_claves.toLowerCase().includes(search.toLowerCase())
      )
    )
  }

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const currentLocale = intl.locale

  const body = (
    <div className={classes.paper}>
      <CssBaseline />

      <TextField
        id="search"
        variant="outlined"
        onChange={e => setSearch(e.currentTarget.value)}
        value={search}
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color="primary" fontSize="small" />
            </InputAdornment>
          ),
        }}
      />
      <div style={{ overflow: "auto", height: "90%", overflowX: "hidden" }}>
        <br />
        <Divider />
        <br />
        <ul>
          {stands.map(stand => (
            <StandPreviewLista key={stand.id} stand={stand} />
          ))}
        </ul>
      </div>
    </div>
  )

  return (
    <div>
      <Tooltip title={currentLocale === "en"
            ? modal_buscador_stands.title.en
            : currentLocale === "pt"
            ? modal_buscador_stands.title.pt
            : modal_buscador_stands.title.es} arrow placement="top">
        <Button
          fullWidth
          // variant={boton_modal_buscadorstands.variante}
          style={{
            background: `${modal_buscador_stands.color_fondo}`,
            color: `${modal_buscador_stands.color_letra}`,
          }}
          size={modal_buscador_stands.size}
          onClick={handleOpen}
        >
          {currentLocale === "en"
            ? modal_buscador_stands.texto.en
            : currentLocale === "pt"
            ? modal_buscador_stands.texto.pt
            : modal_buscador_stands.texto.es}
        </Button>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  )
}

export default injectIntl(ModalBuscadorStands)
