import React, { useState, useEffect } from "react"
import usePabellones from "../hooks/usePabellones"
import PabellonPreview from "./PabellonPreview"

const ListadoPabellones = () => {
  const resultado = usePabellones()
  const [pabellones, guardarPabellones] = useState([])

  useEffect(() => {
    guardarPabellones(resultado)
  }, [])

  return (
    <>
      {pabellones.map(pabellon =>
        pabellon.pabellon_oculto ? (
          ""
        ) : (
          <ul
            key={pabellon.id}
            style={{
              width: pabellon.width,
              position: "absolute",
              left: pabellon.left,
              top: pabellon.top,
            }}
          >
            <PabellonPreview key={pabellon.id} pabellon={pabellon} />
          </ul>
        )
      )}
    </>
  )
}

export default ListadoPabellones
