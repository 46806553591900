import React, { useEffect, useState } from "react"
import { makeStyles, Modal, Button } from "@material-ui/core"
import { GatsbyImage } from "gatsby-plugin-image"
import "../css/background-image.css"
import { navigate } from "gatsby-plugin-intl"

const useStyles = makeStyles(theme => ({
  paper: {
    position: "relative",
    top: "auto",
    left: "auto",
    right: "auto",
    bottom: "auto",
    margin: "32px auto",
    padding: 0,
  },
  thumb: {
    width: "100%",
  },
}))

const Carteles = props => {
  const {
    proporcion,
    ampliar_cartel,
    tiempo_slideshow,
    // width,
    mostrar,
    color_fondo_marco,
    slideshow_imagenes,
    marco_size,
  } = props.cartel
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  // const ximagenfija = proporcion === "horizontal" ? 16 : 9
  // const yimagenfija = proporcion === "horizontal" ? 9 : 16
  //proporcion de imagen
  const widthVentana = window.innerWidth
  const heightVentana = window.innerHeight
  const pixelesAlto = (heightVentana * 80) / 100
  // const widthInt = parseInt(width)
  // const pixelesAncho = (widthInt * widthVentana) / 100
  // let altura
  let anchoModal

  const anchoPorcentajeImagen = (600 * 100) / widthVentana
  const altoParaAncho = (pixelesAlto / 16) * 9
  const anchoooo = (altoParaAncho * 100) / widthVentana

  /* useEffect(() => { */
  // if (props.x === 9) {
  if (proporcion === "vertical") {
    // altura = (pixelesAncho / 9) * 16
    anchoModal = anchoooo
  } else {
    // altura = (pixelesAncho / 16) * 9
    anchoModal = anchoPorcentajeImagen
  }

  /*   let x = 16
    let y = 9
    if (proporcion == "vertical") {
      x =nd  9
      y = 16
    } */

  //const ratio = x / y
  let i
  const [ind, setInd] = useState(0)
  const cambiarImagen = () => {
    if (i < slideshow_imagenes.length - 1) {
      i = i + 1
      setInd(ind => ind + 1)
    } else {
      setInd(0)
      i = 0
    }
  }

  const irA = () => {
    if (slideshow_imagenes[ind].imagen_link === " ") {
    } else {
      if (slideshow_imagenes[ind].imagen_link_tipo === "interno") {
        navigate(slideshow_imagenes[ind].imagen_link)
      } else {
        window.open(slideshow_imagenes[ind].imagen_link, "_blank")
      }
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      cambiarImagen()
    }, tiempo_slideshow)
    return () => clearInterval(interval)
  }, [])
  const body = (
    <div className={classes.paper} style={{ width: `${anchoModal}%` }}>
      <Button type="button" onClick={irA}>
        {slideshow_imagenes[ind].imagen !== null &&
        slideshow_imagenes[ind].imagen.localFile !== null ? (
          <GatsbyImage
            image={
              slideshow_imagenes[ind].imagen.localFile.childImageSharp
                .gatsbyImageData
            }
            alt="imagen"
          />
        ) : (
          ""
        )}
      </Button>
    </div>
  )

  //proporcion de imagen
  //const widthVentana = window.innerWidth
  // const widthInt = parseInt(width)
  /*   const pixelesAncho = (widthInt * widthVentana) / 100
  let altura
  // REVISAR SI ESTO VA EN UN USE EFFECT METER CONSOLE LOG ADENTRO
  if (proporcion === "vertical") {
    altura = (pixelesAncho / 9) * 16
  } else {
    altura = (pixelesAncho / 16) * 9
  } */

  if (ampliar_cartel) {
    return (
      <>
        {mostrar ? (
          <>
            <Button
              type="button"
              onClick={handleOpen}
              style={{
                background: `${color_fondo_marco}`,
                padding: marco_size,
              }}
            >
              {slideshow_imagenes[ind].imagen !== null &&
              slideshow_imagenes[ind].imagen.localFile !== null ? (
                <GatsbyImage
                  image={
                    slideshow_imagenes[ind].imagen.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  alt="imagen"
                  //   className={classes.thumb}
                  //        style={{ height: altura }}
                />
              ) : (
                ""
              )}
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              {body}
            </Modal>
          </>
        ) : (
          ""
        )}
      </>
    )
  } else {
    return (
      <>
        {mostrar ? (
          <Button
            style={{ background: `${color_fondo_marco}`, padding: marco_size }}
            type="button"
            onClick={irA}
          >
            {slideshow_imagenes[ind].imagen !== null &&
            slideshow_imagenes[ind].imagen.localFile !== null ? (
              <GatsbyImage
                image={
                  slideshow_imagenes[ind].imagen.localFile.childImageSharp
                    .gatsbyImageData
                }
                alt="imagen"
                //       style={{ height: altura }}
              />
            ) : (
              ""
            )}
          </Button>
        ) : (
          ""
        )}
      </>
    )
  }
}

export default Carteles
