import React, { useState, useEffect } from "react"
import usePabellones from "../hooks/usePabellones"
import PabellonPreview from "./PabellonPreview"

const ListadoPabellonesMovil = () => {
  const resultado = usePabellones()
  const [pabellones, guardarPabellones] = useState([])

  useEffect(() => {
    guardarPabellones(resultado)
  }, [])

  return (
    <>
      {pabellones.map(pabellon =>
        pabellon.pabellon_oculto ? (
          ""
        ) : (
          <ul key={pabellon.id}>
            <PabellonPreview key={pabellon.id} pabellon={pabellon} />
          </ul>
        )
      )}
    </>
  )
}

export default ListadoPabellonesMovil
